import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { colors, mediaQueries } from "../components/GlobalStyles"
import MailIcon from "../images/gmail.svg"
import FacebookIcon from "../images/facebook.svg"
import TwitterIcon from "../images/twitter.svg"
import GitHubIcon from "../images/github.svg"
import LinkedInIcon from "../images/linkedin.svg"
import Steven from "../images/steven.jpg"
import H1 from "../components/H1"
import HeroHeader from "../components/HeroHeader"
import Container from "../components/Container"
import H2 from "../components/H2"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Steven Musumeche | JavaScript TypeScript Node.js Developer | Lafayette, LA"
      keywords={[`lafayette developer`, `lafayette software engineer`]}
    />
    <HeroHeader>
      <SelfImage alt="Steven Musumeche" src={Steven} />
      <H1>Steven Musumeche</H1>
      <H2>Senior Software Engineer</H2>
    </HeroHeader>
    <Container flex={true}>
      <section css={``}>
        <h2>About me</h2>
        <div
          css={`
            padding-right: 2em;
          `}
        >
          <p>
            I am a seasoned T-shaped software engineer, deeply-skilled in
            front-end technologies like ReactJS, TypeScript, and GraphQL,
            complemented by impactful contributions to high-scale distributed
            backend systems. I work remotely from Lafayette, LA.
          </p>
          <p>
            I currently work as a software engineer for a stealth-mode startup
            which operates at the intersection of real estate and food,
            connecting the digital and physical worlds with software (bits and
            atoms). With over two decades in the tech trenches, I've mastered
            the art of making complex systems play nicely together - think of it
            as a digital orchestra, but with fewer musical instruments.
          </p>
          <p>
            In my spare time, I enjoy hanging out with my wife, Dr. Adrienne
            Musumeche, and kids Molly, Ruby &amp; Ross. I also enjoy saltwater
            fishing, reading, drumming and playing with my beagle, Annie, as
            well as my two Vizslas, Sandor and Lili.
          </p>
          <p>
            After graduating from Louisiana State University with a degree in
            both Music and Information Systems (summa cum laude), I started my
            career by co-founding the e-commerce startup, Internet Retail
            Connection, eventually growing it into a $5M business. At IRC, I
            wore many hats, including application development, architecture,
            systems administration, search engine optimization (SEO) and online
            marketing.
          </p>
          <p>
            After ten years of being the boss, it was time for a change, so I
            joined Chicago-based Dealer Inspire to lead development of a
            commercially-successful live chat SaaS product catering to the
            automotive industry.
          </p>
          <p>
            After the company was acquired by Cars.com in early 2018, I joined
            Seattle-based Formidable Labs as a senior full-stack software
            engineer, where I focused on open source and JavaScript-based client
            projects for companies ranging in size from startups to Fortune
            100s.
          </p>
          <p>
            I'm currenly working for a quickly-growing startup where I work with
            distributed systems, GraphQL, TypeScript, Java, gRPC, CockroachDB,
            and other fun technologies.
          </p>
        </div>
      </section>
      <section css={``}>
        <h2>Contact</h2>
        <ul
          css={`
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            ${mediaQueries.small} {
              & li {
                width: 45%;
              }
            }
          `}
        >
          <li>
            <ContactLink href="mailto:steven@musumeche.com" color="#D14836">
              <ContactIconWrapper>
                <ContactIcon src={MailIcon} alt="email icon" />
              </ContactIconWrapper>
              <div>Email</div>
            </ContactLink>
          </li>
          <li>
            <ContactLink href="https://twitter.com/smusumeche" color="#1DA1F2">
              <ContactIconWrapper>
                <ContactIcon src={TwitterIcon} alt="twitter icon" />
              </ContactIconWrapper>{" "}
              <div>Twitter</div>
            </ContactLink>
          </li>
          <li>
            <ContactLink
              href="https://www.facebook.com/musumeche"
              color="#3B5998"
            >
              <ContactIconWrapper>
                <ContactIcon src={FacebookIcon} alt="facebook icon" />
              </ContactIconWrapper>{" "}
              <div>Facebook</div>
            </ContactLink>
          </li>
          <li>
            <ContactLink
              href="https://github.com/stevenmusumeche"
              color="#bd2c00"
            >
              <ContactIconWrapper>
                <ContactIcon src={GitHubIcon} alt="github icon" />
              </ContactIconWrapper>{" "}
              <div>GitHub</div>
            </ContactLink>
          </li>
          <li>
            <ContactLink
              href="https://www.linkedin.com/in/smusumeche/"
              color="#0077B5"
            >
              <ContactIconWrapper>
                <ContactIcon src={LinkedInIcon} alt="linkedin icon" />
              </ContactIconWrapper>{" "}
              <div>LinkedIn</div>
            </ContactLink>
          </li>
        </ul>
      </section>
    </Container>
  </Layout>
)

const SelfImage = styled.img`
  width: 7em;
  border-radius: 50%;
  padding: 0.5em;
  background-color: ${colors.white};
  border: 1px solid ${colors.darkGrey};
  ${mediaQueries.small} {
    width: 5em;
    padding: 0.3em;
  }
`

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  color: ${colors.black};
  text-decoration: none;
  & :hover {
    color: ${props => props.color || "pink"};
    > div:first-child {
      background-color: ${props => props.color || "pink"};
    }
  }
`

const ContactIconWrapper = styled.div`
  background-color: ${colors.black};
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
`

const ContactIcon = styled.img`
  width: 55%;
`

export default IndexPage
